var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-wrapper',{attrs:{"variant":"fullscreen"}},[_c('base-card',{attrs:{"customClass":"px-3 py-4 mb-4 mb-md-6"}},[_c('h3',[_vm._v("Konfirmasi Pembayaran")]),_c('v-data-table',{attrs:{"items":_vm.userUnconfirmedFunds,"headers":_vm.confirmationHeaders},scopedSlots:_vm._u([{key:"item.proposal.title",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticClass:"py-3",attrs:{"column":""}},[_c('h4',[_vm._v(_vm._s(item.proposal.title))]),_c('v-layout',{staticClass:"align-center"},[_c('base-avatar',{staticClass:"mr-2 mt-2",attrs:{"user":item.proposal.user,"size":32}}),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.toDisplayName(item.proposal.user))+" ")])],1)],1)]}},{key:"item.nominal",fn:function(ref){
var value = ref.value;
return [_c('v-layout',[_c('span',{staticClass:"text--disabled"},[_vm._v("Rp.")]),_c('v-spacer'),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.toCurrency(value)))])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.selectFund(item)}}},[_vm._v(" Konfirmasi ")])]}}])})],1),_c('base-dialog',{attrs:{"title":"Konfirmasi Pembayaran"},model:{value:(_vm.showDialogConfirm),callback:function ($$v) {_vm.showDialogConfirm=$$v},expression:"showDialogConfirm"}},[(_vm.selectedFund)?_c('v-sheet',{staticClass:"px-3 py-4 mb-4",attrs:{"rounded":"","color":"info lighten-4"}},[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                        ("Konfirmasi pembayaran ke proposal\n                        <strong>" + (_vm.selectedFund.proposal.title) + "</strong> sebesar\n                        <strong>" + (_vm.selectedFund.nominal) + "</strong> ?")
                    )}})]):_vm._e(),_c('v-btn',{staticClass:"text-none",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.confirmConfirmationFund}},[_vm._v(" Konfirmasi ")]),_c('v-btn',{staticClass:"mt-3 text-none",attrs:{"depressed":"","color":"button"},on:{"click":function($event){(_vm.showDialogConfirm = false), (_vm.selectedFund = null)}}},[_vm._v(" Batal ")])],1),_c('shared-dashboard-statistics',{attrs:{"loading":_vm.isLoading,"statistics":_vm.dataStatistics}}),_c('shared-dashboard-charts',{attrs:{"loading":_vm.isLoading,"funds":_vm.funds,"statistics":_vm.dataStatistics,"selectedYear":_vm.selectedYear}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }