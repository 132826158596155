












































































































import BaseDialog from "../../../components/Base/BaseDialog.vue";
import BaseAvatar from "../../../components/Base/BaseAvatar.vue";
import BaseCard from "../../../components/Base/BaseCard.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedDashboardCharts from "@/components/Shared/Dashboard/Charts.vue";
import SharedDashboardStatistics from "@/components/Shared/Dashboard/Statistics.vue";
import { Component, Vue } from "vue-property-decorator";
import { toDisplayName, toCurrency } from "@/helpers/Formatter";

@Component({
    components: {
        BaseWrapper,
        SharedDashboardStatistics,
        SharedDashboardCharts,
        BaseCard,
        BaseAvatar,
        BaseDialog,
    },
})
export default class CompanyDashboard extends Vue {
    public selectedYear: number = new Date().getFullYear();
    public isLoading: boolean = false;

    toDisplayName(user: any) {
        return toDisplayName(user);
    }

    toCurrency(value: any) {
        return toCurrency(value, false);
    }

    get me() {
        return this.$store.state.users.me;
    }

    get statistics() {
        return this.$store.state.statistics.companyStatistics;
    }

    get funds() {
        return this.$store.state.funds.funds;
    }

    get dataStatistics() {
        let statistics: any = [
            {
                title: "Rencana Dana CSR",
                icon: "mdi-bank",
                value: 0,
                color: "primary",
                type: "currency",
            },
            {
                title: "Dana Yang Telah Diberikan",
                icon: "mdi-bank-transfer-out",
                value: 0,
                color: "error",
                type: "currency",
            },
            {
                title: "Proposal Diberikan Dana",
                icon: "mdi-clipboard-list",
                value: 0,
                color: "warning",
                suffix: "proposal",
            },
        ];

        if (!this.statistics || !this.statistics.plan.length) return statistics;

        statistics = [
            {
                title: "Rencana Dana CSR",
                icon: "mdi-bank",
                value: this.statistics.plan[0].fund,
                color: "primary",
                type: "currency",
            },
            {
                title: "Dana Yang Telah Diberikan",
                icon: "mdi-bank-transfer-out",
                value: this.usedFund,
                color: "error",
                type: "currency",
            },
            {
                title: "Proposal Diberikan Dana",
                icon: "mdi-clipboard-list",
                value: this.statistics.fund.length,
                color: "warning",
                suffix: "proposal",
            },
        ];

        return statistics;
    }

    get usedFund() {
        if (!this.statistics) return null;

        let usedFund: number = 0;

        this.statistics.fund.map((fund: any) => {
            usedFund += fund.nominal;
        });

        return usedFund;
    }

    get userUnconfirmedFunds() {
        return this.$store.state.funds.userUnconfirmedFunds;
    }

    async getUserFunds() {
        const payload = {
            user: this.me.id,
            year: this.selectedYear,
        };
        const funds = await this.$store.dispatch(
            "funds/getAllFundByUser",
            payload
        );
    }

    async getUserUnconfirmedFunds() {
        const userId = this.me.id;
        const response = await this.$store.dispatch(
            "funds/getUserUnconfirmedFunds",
            userId
        );
    }

    public showDialogConfirm: boolean = false;
    public selectedFund: any = null;
    async selectFund(item: any) {
        this.selectedFund = item;
        this.showDialogConfirm = true;
    }

    public confirmationHeaders: any[] = [
        { text: "Proposal", value: "proposal.title" },
        { text: "Nominal", value: "nominal", align: "right" },
        { text: "", value: "actions", align: "right" },
    ];

    async confirmConfirmationFund() {
        this.isLoading = true;
        const fundId = this.selectedFund.id;
        const response = await this.$store.dispatch(
            "funds/confirmFund",
            fundId
        );

        await this.getUserUnconfirmedFunds();

        this.isLoading = false;
        this.showDialogConfirm = false;
    }

    async mounted() {
        this.isLoading = true;

        const payload = { userId: this.me.id, year: this.selectedYear };
        const plans = await this.$store.dispatch(
            "plans/getDashboardPlan",
            payload
        );
        const statistics = await this.$store.dispatch(
            "statistics/getCompanyStatistics",
            payload
        );
        const funds = await this.getUserFunds();

        await this.getUserUnconfirmedFunds();

        this.isLoading = false;
    }
}
